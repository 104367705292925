// =================================================================================================
// This "embeds" a Journey Section, allowing cancelable changes to be made to your quote.
// =================================================================================================

import { Alert, AlertTitle, Button, CircularProgress, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { patchQuote } from "../apiCalls";
import { validateField } from "../fieldValidation";
import { checkDependents, checkOptionDependents, getFormData } from "../formHandling";
import { JourneyContext } from "../JourneyContext";
import {
  IStringIndex,
  TAlert,
  TFieldChangeHandler,
  TFormData,
  TFormField,
  TJourneyConfig,
  TJourneySection,
} from "../types";
import { JourneySection } from "./JourneySection";

// =================================================================================================
// Main component
// =================================================================================================

export const EmbeddedJourneySection = (props: {
  section: TJourneySection;
  config: TJourneyConfig;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const journeyContext = useContext(JourneyContext);
  const { section, config, onCancel, onSuccess } = props;

  const { activeIndex } = useContext(JourneyContext);
  const [localFormData, setLocalFormData] = useState<TFormData>(journeyContext.formData);
  const [busy, setBusy] = useState(false);
  const [submitAlert, setSubmitAlert] = useState<TAlert | undefined>();

  const nextSection = config.sections[activeIndex + 1];
  const currentSection = config.sections[activeIndex];

  useEffect(() => {
    const formData = getFormData(
      section.fields,
      journeyContext.formData,
      journeyContext.mode,
      config
    );
    setLocalFormData(formData);
  }, [section, journeyContext]);

  const localChangeHandler: TFieldChangeHandler = (field: TFormField, newValue: any) => {
    // console.log(`Field "${field.name}" has new value ${JSON.stringify(newValue)}`);
    let newValidations = { ...localFormData.validations };
    let newValues = { ...localFormData.values, [field.name]: newValue };

    const validation = validateField(field, newValue, newValues);
    newValidations[field.name] = validation;
    let allFields: TFormField[] = [];
    for (let s of config.sections) {
      allFields = [...allFields, ...s.fields];
    }
    checkDependents(field, allFields, newValues, newValidations, config);
    checkOptionDependents(field, allFields, newValues, newValidations);
    const newFormData: TFormData = {
      values: { ...localFormData.values, ...newValues },
      validations: { ...localFormData.validations, ...newValidations },
    };
    setLocalFormData(newFormData);
  };

  const visibleFields = section?.fields.filter((f) => !f.hidden) || [];
  let allValid = true;
  for (let f of visibleFields) {
    if (localFormData.validations[f.name] !== true) {
      allValid = false;
      break;
    }
  }

  const submitUpdate = async () => {
    const cleanValues: IStringIndex<any> = {};
    for (let [fieldName, fieldValue] of Object.entries(localFormData.values)) {
      if (typeof fieldValue === "string") {
        cleanValues[fieldName] = fieldValue.replace(/\s+/g, " ").trim();
      } else {
        cleanValues[fieldName] = fieldValue;
      }
    }

    // If API is not configured, or we are editing, just update journeyContext and return
    if (!config?.APIs?.patch || journeyContext.mode === "EDIT") {
      journeyContext.setFormData({
        values: cleanValues,
        validations: { ...localFormData.validations },
      });
      onSuccess();
      return;
    }

    // Actually update the quote
    setBusy(true);
    if (submitAlert) {
      setSubmitAlert(undefined);
    }

    // MTA preview
    if (journeyContext.mode === "MTA") {
      const apiCopy = JSON.parse(JSON.stringify(config.APIs?.mta));
      apiCopy.payload.quote.journey = "mta_preview";
      const result = await patchQuote(config, apiCopy, cleanValues);
      if (
        result === null ||
        result?.errorMessage ||
        !Array.isArray(result) ||
        result.length !== 1
      ) {
        setSubmitAlert({
          body: "There was a problem previewing the MTA, please wait a moment and try again.",
          severity: "error",
        });
      } else {
        journeyContext.setFormData({
          values: {
            ...cleanValues,
            MTA: result[0],
          },
          validations: { ...localFormData.validations },
        });
        onSuccess();
      }
    } else {
      // Actual quote update
      if (nextSection?.isQuote || currentSection?.isQuote) {
        config.APIs.patch.payload.quote.isQuote = true;
      }
      const result = await patchQuote(config, config!.APIs!.patch, cleanValues);
      if (
        result === null ||
        result?.errorMessage ||
        !Array.isArray(result) ||
        result.length !== 1
      ) {
        setSubmitAlert({
          body: "There was a problem updating your quote, please wait a moment and try again.",
          severity: "error",
        });
      } else {
        journeyContext.setFormData({
          values: {
            ...cleanValues,
            quote: result[0],
          },
          validations: { ...localFormData.validations },
        });
        onSuccess();
      }
    }
    setBusy(false);
  };

  const renderSubmitAlert = () => {
    if (!submitAlert) {
      return;
    }
    return (
      <Alert
        severity={submitAlert.severity}
        sx={{ marginTop: 2, border: "1px solid rgba(0,0,0,0.5)" }}
        onClose={() => setSubmitAlert(undefined)}
      >
        {submitAlert.title && <AlertTitle>{submitAlert.title}</AlertTitle>}
        {submitAlert.body}
      </Alert>
    );
  };

  return (
    <JourneyContext.Provider
      value={{ formData: localFormData, setFormData: setLocalFormData, mode: journeyContext.mode }}
    >
      <JourneySection
        mode={journeyContext.mode}
        section={{ ...section, heading: "Adjust your cover" }}
        formData={localFormData}
        changeHandler={localChangeHandler}
        config={config}
        disabled={busy}
      />
      {submitAlert && renderSubmitAlert()}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ marginTop: 4 }}
      >
        {busy ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            <Button className="dialogCloseButton" disabled={busy} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="submitButton"
              fullWidth
              variant="contained"
              size="large"
              disabled={!allValid}
              onClick={submitUpdate}
            >
              Update Quote
            </Button>
          </>
        )}
      </Stack>
    </JourneyContext.Provider>
  );
};
