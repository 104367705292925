const response = await fetch("https://quotes.nationalfriendly.co.uk/api/accidentonly/occupations")

let data
try {
  data = await response.json()
}catch(error){
  console.log("error fetching national friendly occupation list")
  throw new Error(`error fetching national friendly occupation list: ${error?.message}`)
}

export const Occupations = data.map((occupation) => {
  occupation["value"] = occupation["Id"]
  occupation["label"] = occupation["Description"]
  return occupation;
})

export const GuaranteedLifeOccupations = data.map((occupation) => {
  occupation["value"] = occupation["Description"]
  occupation["label"] = occupation["Description"]
  return occupation;
}).sort((a,b) =>
   a.value.localeCompare(b.value)
  )

