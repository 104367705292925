/* eslint-disable no-template-curly-in-string */
import {
  TFormData,
  TFormField,
  IStringIndex,
  TJourneyConfig,
  TJourneyMode,
  TJourneyInfo,
  TLink,
  CreateNumberofClaimsText,
  OptionsFromTotalIncurred,
} from './types';
import { validateField } from './fieldValidation';
import moment from 'moment';
import { camelCase, get as lodash_get } from 'lodash';
import { getDataSourceOptions } from './codeLists/dataSource';
import { Engine } from 'json-rules-engine';
import { applyRule, handleUpdateRule } from './utils/rulesEngine';
import { formatToMoney } from './utils/common';
import { getEnvParams } from './utils';

type TOpt = {
  disabled: boolean;
  label: string;
  value: any;
  tooltip?: string;
};

const setParentOptionDependents = (field: TFormField, conditions: IStringIndex<any>[], fields: TFormField[]) => {
  //console.log("setParentOptionDependents", field.name);
  for (let c of conditions) {
    if (c.anyOf) {
      setParentOptionDependents(field, c.anyOf, fields);
      continue;
    } else if (c.allOf) {
      setParentOptionDependents(field, c.allOf, fields);
      continue;
    } else if (c.notAnyOf) {
      setParentOptionDependents(field, c.notAnyOf, fields);
      continue;
    } else if (c.notAllOf) {
      setParentOptionDependents(field, c.notAllOf, fields);
      continue;
    } else if (c.anyGreaterThan) {
      setParentOptionDependents(field, c.anyGreaterThan, fields);
      continue;
    } else if (c.anySizeEquals) {
      setParentOptionDependents(field, c.anySizeEquals, fields);
      continue;
    } else if (c.anyContains) {
      setParentOptionDependents(field, c.anyContains, fields);
      continue;
    }
    // Find parents from condition
    let conditionName = Object.keys(c)[0];
    if (conditionName.includes('.')) {
      conditionName = conditionName.split('.')[0];
    }
    let parents = fields.filter((field) => field.name === conditionName);
    for (let p of parents) {
      if (p.optionDependents === undefined) {
        p.optionDependents = [];
      }
      // Add field name to list of parent's dependents
      if (!p.optionDependents.includes(field.name)) {
        p.optionDependents.push(field.name);
      }
    }
  }
};

const setParentDependents = (field: TFormField, conditions: IStringIndex<any>[], fields: TFormField[]) => {
  field.hidden = true;
  // console.log("setParentDependents", field.name);
  for (let c of conditions) {
    if (c.anyOf) {
      setParentDependents(field, c.anyOf, fields);
      continue;
    } else if (c.allOf) {
      setParentDependents(field, c.allOf, fields);
      continue;
    } else if (c.notAnyOf) {
      setParentDependents(field, c.notAnyOf, fields);
      continue;
    } else if (c.notAllOf) {
      setParentDependents(field, c.notAllOf, fields);
      continue;
    } else if (c.anyGreaterThan) {
      setParentDependents(field, c.anyGreaterThan, fields);
      continue;
    } else if (c.anySizeEquals) {
      setParentDependents(field, c.anySizeEquals, fields);
      continue;
    } else if (c.anyContains) {
      setParentDependents(field, c.anyContains, fields);
      continue;
    }
    // Find parents from condition
    let conditionName = Object.keys(c)[0];

    if (conditionName.includes('.')) {
      conditionName = conditionName.split('.')[0];
    }
    let parents = fields.filter((field) => field.name === conditionName);
    for (let p of parents) {
      if (p.dependents === undefined) {
        p.dependents = [];
      }
      // Add field name to list of parent's dependents
      if (!p.dependents.includes(field.name)) {
        p.dependents.push(field.name);
      }
    }
  }
};

export const getReferrals = (config: TJourneyConfig, values: IStringIndex<any>) => {
  const referrals: IStringIndex<any> = {};
  if (config.referrals) {
    for (let [fieldName, fieldValue] of Object.entries(config.referrals)) {
      if (values[fieldName] === fieldValue) {
        referrals[fieldName] = fieldValue;
      }
    }
  }
  return referrals;
};

export const getSectionedAnswers = (config: TJourneyConfig, values: IStringIndex<any>) => {
  const answers: IStringIndex<any> = {};
  const finalIndex = config.sections.findIndex((section) => section.isFinal) ?? config.sections.length - 1;
  for (let idx = 0; idx < finalIndex + 1; idx++) {
    const section = config.sections[idx];
    const sectionName = camelCase(section.name);
    answers[sectionName] = [];
    for (let field of section.fields) {
      if (field.restrictToMode === 'MTA') {
        continue; // Is this ever something that would be collected?
      }
      if (values[field.name] !== undefined) {
        answers[sectionName].push({
          name: camelCase(field.name),
          question: field.label ?? field.title,
          answer: values[field.name],
        });
      }
    }
  }
  return answers;
};

export const initDependencies = (fields: TFormField[], mode: TJourneyMode) => {
  for (let f of fields) {
    if (f.restrictToMode !== undefined && !f.restrictToMode.includes(mode)) {
      console.log(`${f.name} is restricted to ${f.restrictToMode} (current mode is ${mode})`);
      f.hidden = true;
    } else {
      if (f.dependsOn?.allOf?.length) {
        setParentDependents(f, f.dependsOn.allOf, fields);
      } else if (f.dependsOn?.anyOf?.length) {
        setParentDependents(f, f.dependsOn.anyOf, fields);
      } else if (f.dependsOn?.notAllOf?.length) {
        setParentDependents(f, f.dependsOn.notAllOf, fields);
      } else if (f.dependsOn?.notAnyOf?.length) {
        setParentDependents(f, f.dependsOn.notAnyOf, fields);
      } else if (f.dependsOn?.anyGreaterThan?.length) {
        setParentDependents(f, f.dependsOn.anyGreaterThan, fields);
      } else if (f.dependsOn?.anySizeEquals?.length) {
        setParentDependents(f, f.dependsOn.anySizeEquals, fields);
      } else if (f.dependsOn?.anyContains?.length) {
        setParentDependents(f, f.dependsOn.anyContains, fields);
      }

      if (f.optionDependencies?.length) {
        for (let d of f.optionDependencies) {
          if (d === null) {
            continue;
          }
          if (d.allOf?.length) {
            setParentOptionDependents(f, d.allOf, fields);
          } else if (d.anyOf?.length) {
            setParentOptionDependents(f, d.anyOf, fields);
          } else if (d.notAllOf?.length) {
            setParentOptionDependents(f, d.notAllOf, fields);
          } else if (d.notAnyOf?.length) {
            setParentOptionDependents(f, d.notAnyOf, fields);
          } else if (d.anyGreaterThan?.length) {
            setParentOptionDependents(f, d.anyGreaterThan, fields);
          } else if (d.anySizeEquals?.length) {
            setParentOptionDependents(f, d.anySizeEquals, fields);
          } else if (d.anyContains?.length) {
            setParentOptionDependents(f, d.anyContains, fields);
          }
        }
      }
    }
  }
};

export const getFormData = (
  fields: TFormField[],
  initialFormData: TFormData,
  mode: TJourneyMode,
  config: TJourneyConfig,
): TFormData => {
  const cleanValues: IStringIndex<any> = {};
  for (let [fieldName, fieldValue] of Object.entries(initialFormData.values)) {
    if (initialFormData.validations[fieldName] === false) {
      // console.log("Not populating", fieldName);
      continue;
    }
    if (typeof fieldValue === 'string') {
      cleanValues[fieldName] = fieldValue.replace(/\s+/g, ' ').trim();
    } else {
      cleanValues[fieldName] = fieldValue;
    }
  }
  const initialValidations = initialFormData.validations;
  const data: TFormData = {
    values: { ...cleanValues },
    validations: { ...initialValidations },
  };
  // console.log("==== FIELDS BEFORE ====");
  // console.log(JSON.stringify(fields, null, 2));
  for (let f of fields) {
    const initialValue = cleanValues[f.name];
    data.values[f.name] = initialValue;
    const validation = validateField(f, initialValue, cleanValues);
    data.validations[f.name] = validation;
  }
  for (let f of fields) {
    // f.dependents && console.log(`Field "${f.name}" has dependents ${JSON.stringify(f.dependents)}`);
    // f.optionDependents &&
    //   console.log(`Field "${f.name}" has option dependents ${JSON.stringify(f.optionDependents)}`);
    checkDependents(f, fields, data.values, data.validations, config, mode);
  }

  // console.log("==== FIELDS AFTER ====");
  // console.log(JSON.stringify(fields, null, 2));
  return data;
};

export const getFieldOptions = (field: TFormField, formData: TFormData): TOpt[] => {
  if (field.options?.includes('###EXTERNAL' as any)) {
    return [
      {
        disabled: false,
        label: 'EXTERNAL',
        value: 'EXTERNAL',
      },
    ];
  }
  const options = field.dataSource ? getDataSourceOptions(field.dataSource) : field.options ?? [];
  return (
    options.map((option, index) => {
      const dependency = field.optionDependencies ? field.optionDependencies[index] : null;
      const disabled = dependency ? !conditionMet(dependency, formData.values, formData.validations) : false;
      return typeof option === 'string'
        ? {
            label: replacePlaceholders(formData.values, option),
            value: replacePlaceholders(formData.values, option),
            disabled,
          }
        : { ...option, disabled };
    }) || []
  );
};

export const createNumberofClaimsText: CreateNumberofClaimsText = (period, options) => {
  const coverPeriod = parseInt(period);
  const numberOfClaims = String(coverPeriod / 4);
  const policyPriodForCoverText = period === '12' ? 'a year' : period + ' months';
  let claimText = `Claim up to ${numberOfClaims} times in ${policyPriodForCoverText}`;

  const newOptions = [...options];
  for (let element of newOptions) {
    if (element?.label === 'coverClaimText') {
      element.label = claimText;
    }
  }

  return newOptions;
};

export const calculateTotalIncurredValue: OptionsFromTotalIncurred = (formData, options) => {
  const currentReserve1 = formData?.currentReserve1;
  const amountPaidSoFar1 = formData?.AmountPaidSoFar1;

  const currentReserve2 = formData?.currentReserve2;
  const amountPaidSoFar2 = formData?.AmountPaidSoFar2;

  if (currentReserve1 === undefined || amountPaidSoFar1 === undefined) {
    return options;
  }

  const totalIncurred1 = currentReserve1 + amountPaidSoFar1;
  formData.totalIncurred1 = totalIncurred1;

  const newOptions = [...options];
  for (let element of newOptions) {
    if (element?.label === 'Total Incurred 1') {
      element.value = `£${totalIncurred1}`;
    }
  }

  if (currentReserve2 === undefined || amountPaidSoFar2 === undefined) {
    return newOptions;
  }

  const totalIncurred2 = currentReserve2 + amountPaidSoFar2;
  formData.totalIncurred2 = totalIncurred2;

  const newOptions2 = [...newOptions];
  for (let element of newOptions2) {
    if (element?.label === 'Total Incurred 2') {
      element.value = `£${totalIncurred2}`;
    }
  }
  return newOptions2;
};

export const basisForCoverLogic = (yearOfManufacture: any, formData: any, options: any) => {
  const currentDate = moment();

  const caravanAge = currentDate.diff(yearOfManufacture, 'years');
  if (caravanAge > 10 && options.name === 'yearOfManufacture') {
    formData.values.whatBasisOfCoverIsRequired = 'Market Value';
  }
};

export const getLinks = (info: TJourneyInfo, formData: TFormData): TLink[] => {
  const insurerName = formData?.values?.quote?.insurerName;

  if (insurerName === 'Helvetia Schweizerische Versicherungsgesellschaft in Liechtenstein AG' && info.HelvetiaLinks) {
    info.links = info.HelvetiaLinks;
  }

  if (
    (insurerName?.includes('Collinson') || formData?.values?.insurerName?.includes("Collinson")) &&
    info.CollinsonLinks
  ) {
    info.links = info.CollinsonLinks;
  }
  
  return info.links
    ? info.links.filter((link) => {
        if (link.dependsOn === undefined) {
          return true;
        }
        return conditionMet(link.dependsOn, formData.values, formData.validations);
      })
    : [];
};

export const checkOptionDependents = (
  field: TFormField,
  fields: TFormField[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
) => {
  // Check if option dependencies are met
  for (let name of field.optionDependents || []) {
    const dependentFields = fields.filter((f) => f.name === name);
    for (let dependentField of dependentFields) {
      const options = getFieldOptions(dependentField, {
        values: newValues,
        validations: newValidations,
      });
      // Try to determine selected option
      const selected = options?.find((opt) => {
        if (typeof opt === 'string') {
          return opt === newValues[dependentField.name];
        } else {
          return opt.value === newValues[dependentField.name];
        }
      });
      if (selected?.disabled) {
        newValues[dependentField.name] = undefined;
        newValidations[dependentField.name] = validateField(dependentField, undefined, newValues);
        //
        if (dependentField.optionDependents) {
          checkOptionDependents(dependentField, fields, newValues, newValidations);
        }
      }
    }
  }
};

const { mode: journeyMode } = getEnvParams();
export const checkDependents = (
  field: TFormField,
  fields: TFormField[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
  config?: TJourneyConfig,
  mode?: TJourneyMode,
) => {
  mode = mode ?? journeyMode ?? 'DEFAULT';
  if (field.dependents?.length) {
    for (let name of field.dependents) {
      const dependentFields = fields.filter((f) => f.name === name);
      for (let dependentField of dependentFields) {
        handleDependsOn(dependentField, newValues, newValidations, config);
        checkDependents(dependentField, fields, newValues, newValidations, config, mode);
      }
    }
  } else if (field.dependsOn) {
    if (field.restrictToMode !== undefined && !field.restrictToMode.includes(mode as any)) {
      console.log(`${field.name} is restricted to ${field.restrictToMode} (current mode is ${mode})`);
      field.hidden = true;
    } else {
      handleDependsOn(field, newValues, newValidations, config);
    }
  }
};

export const conditionMet = (
  condition: IStringIndex<any>,
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
  conditionName?: string,
) => {
  if (condition.anyOf) {
    return anyConditionsMet(condition.anyOf, newValues, newValidations);
  } else if (condition.allOf) {
    return allConditionsMet(condition.allOf, newValues, newValidations);
  } else if (condition.notAnyOf) {
    return notAnyConditionsMet(condition.notAnyOf, newValues, newValidations);
  } else if (condition.notAllOf) {
    return notAllConditionsMet(condition.notAllOf, newValues, newValidations);
  } else if (condition.anyGreaterThan) {
    return anyGreaterThanMet(condition.anyGreaterThan, newValues, newValidations);
  } else if (condition.anySizeEquals) {
    return anySizeEqualsMet(condition.anySizeEquals, newValues, newValidations);
  } else if (condition.anyContains) {
    return anyContainsMet(condition.anyContains, newValues, newValidations);
  }

  const parentName = Object.keys(condition)[0];
  const actualValue = lodash_get(newValues, parentName);

  const expectedValue = condition[parentName];
  if (typeof expectedValue === 'object' && expectedValue !== null) {
    if (expectedValue.includesValue !== undefined && actualValue !== undefined) {
      for (let v of actualValue) {
        if (v.value === expectedValue.includesValue) {
          return true;
        }
      }
      return false;
    }

    if (
      expectedValue.valueRange !== undefined &&
      Array.isArray(expectedValue.valueRange) &&
      expectedValue.valueRange.length === 2 &&
      actualValue !== undefined
    ) {
      const min = expectedValue.valueRange[0];
      const max = expectedValue.valueRange[1];
      const numberValue = Number(actualValue);
      //console.log({ min, max, numberValue });
      if (numberValue >= min && numberValue <= max) {
        return true;
      } else {
        return false;
      }
    } else if (
      expectedValue.ageRange !== undefined &&
      Array.isArray(expectedValue.ageRange) &&
      expectedValue.ageRange.length === 2 &&
      actualValue !== undefined
    ) {
      const now = moment();
      const dateFormat = expectedValue.dateFormat ?? (actualValue.length === 4 ? 'YYYY' : 'DD-MM-YYYY');
      const m = moment(actualValue, dateFormat);
      if (m.isValid()) {
        const age = now.diff(m, expectedValue.unit ?? 'years');
        return expectedValue.ageRange[0] <= age && age <= expectedValue.ageRange[1];
      }
    }
    return false;
  } else if (expectedValue === 'any') {
    return newValidations[parentName] === true && newValues[parentName] !== undefined;
  } else if (conditionName === 'anyGreaterThan' && typeof expectedValue === 'number') {
    if (Array.isArray(actualValue)) {
      return actualValue.length > expectedValue;
    }
    return actualValue > expectedValue;
  } else if (conditionName === 'anySizeEquals') {
    if (Array.isArray(actualValue)) {
      return actualValue.length === expectedValue;
    }
  } else if (conditionName === 'anyContains') {
    console.log(actualValue, expectedValue);
    if (Array.isArray(actualValue)) {
      const containsValue = actualValue?.some((v) => v.value === expectedValue);
      return containsValue;
    }
  } else {
    return expectedValue === actualValue;
  }
};

const allConditionsMet = (
  conditions: IStringIndex<any>[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
) => {
  for (let c of conditions) {
    const allMet = conditionMet(c, newValues, newValidations);
    if (allMet === false) {
      return false;
    }
  }
  return true;
};

const anyGreaterThanMet = (
  conditions: IStringIndex<any>[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
) => {
  for (let c of conditions) {
    const allGreater = conditionMet(c, newValues, newValidations, 'anyGreaterThan');
    if (allGreater) {
      return true;
    }
  }
  return false;
};
const anySizeEqualsMet = (
  conditions: IStringIndex<any>[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
) => {
  for (let c of conditions) {
    const allGreater = conditionMet(c, newValues, newValidations, 'anySizeEquals');
    if (allGreater) {
      return true;
    }
  }
  return false;
};

const anyContainsMet = (
  conditions: IStringIndex<any>[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
) => {
  for (let c of conditions) {
    const allGreater = conditionMet(c, newValues, newValidations, 'anyContains');
    if (allGreater) {
      return true;
    }
  }
  return false;
};

const anyConditionsMet = (
  conditions: IStringIndex<any>[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
) => {
  for (let c of conditions) {
    const anyMet = conditionMet(c, newValues, newValidations);
    if (anyMet) {
      return true;
    }
  }
  return false;
};

const notAllConditionsMet = (
  conditions: IStringIndex<any>[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
) => {
  return !allConditionsMet(conditions, newValues, newValidations);
};

const notAnyConditionsMet = (
  conditions: IStringIndex<any>[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
) => {
  return !anyConditionsMet(conditions, newValues, newValidations);
};

function handleDependsOn(
  dependentField: TFormField,
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
  config: TJourneyConfig | undefined,
) {
  let conditionsMet = false;
  if (dependentField.dependsOn?.allOf) {
    conditionsMet = allConditionsMet(dependentField.dependsOn.allOf, newValues, newValidations);
  } else if (dependentField.dependsOn?.anyOf) {
    conditionsMet = anyConditionsMet(dependentField.dependsOn.anyOf, newValues, newValidations);
  } else if (dependentField.dependsOn?.notAllOf) {
    conditionsMet = notAllConditionsMet(dependentField.dependsOn.notAllOf, newValues, newValidations);
  } else if (dependentField.dependsOn?.notAnyOf) {
    conditionsMet = notAnyConditionsMet(dependentField.dependsOn.notAnyOf, newValues, newValidations);
  } else if (dependentField.dependsOn?.anyGreaterThan) {
    conditionsMet = anyGreaterThanMet(dependentField.dependsOn.anyGreaterThan, newValues, newValidations);
  } else if (dependentField.dependsOn?.anySizeEquals) {
    conditionsMet = anySizeEqualsMet(dependentField.dependsOn.anySizeEquals, newValues, newValidations);
  } else if (dependentField.dependsOn?.anyContains) {
    conditionsMet = anyContainsMet(dependentField.dependsOn.anyContains, newValues, newValidations);
  }

  if (dependentField.name === 'pcMtaQuoteSummary' && newValues['paymentFrequency'] === 'MONTHLY') {
    if (Number(newValues?.MTA?.mtaFee) === 0 && Number(newValues?.MTA?.mtaAdjustment) === 0) {
      dependentField.hidden = true;
      conditionsMet = false;
    }
  }

  if (dependentField.hidden !== !conditionsMet) {
    dependentField.hidden = !conditionsMet;
    // console.log(`Field "${dependentField.name}" is now ${dependentField.hidden ? 'hidden' : 'visible'}`);
    if (dependentField.hidden) {
      if (dependentField.type === 'setter') {
        for (let k of dependentField.setterParams!.keys) {
          newValues[k] = undefined;
        }
      } else {
        newValues[dependentField.name] = config?.initialValues?.[dependentField.name] ?? undefined;
        newValues[dependentField.name] = config?.initialValues![dependentField.name] ?? undefined;
      }
    }
    // auto opens array modal when field becomes visible
    if (!dependentField.hidden && dependentField.type === 'array' && dependentField?.arrayParams?.autoOpenModal) {
      dependentField.arrayParams.autoOpen = true;
    }
    newValidations[dependentField.name] = validateField(dependentField, newValues[dependentField.name], newValues);
  }
}

export async function checkRules(
  field: TFormField,
  allFields: TFormField[],
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
  config: TJourneyConfig,
) {
  if (field.dependents && !field.rules) {
    const dependentFields = field.dependents.map((name) => allFields.find((f) => f.name === name));
    for (let f of dependentFields) {
      if (f?.rules) {
        const result = await applyRule(f.rules, newValues);
        if (result && result.conditionMet) {
          if (result.results?.event?.type === 'show') {
            const messages = result.results?.event?.params?.messages;
            if (messages) {
              const conditions = result.results.conditions as any;
              const op = conditions.operator;
              const metCondition = conditions[op].find((condition: any) => condition.result === true);
              const message = replacePlaceholders(newValues, messages[metCondition.operator]);
              f.title = message;
            }
            const initialValue = config?.initialValues![f.name];
            if (!newValues[f.name]) {
              newValues[f.name] = initialValue;
            }
            f.hidden = false;
            newValidations[f.name] = validateField(f, newValues[f.name], newValues);
          } else if (result && result.results?.event?.type === 'updateOptions') {
            const options = result.results?.event?.params?.options;
            if (options) {
              f.options = options;
            }
            f.hidden = false;
          }
        } else if (f?.rules?.event?.type === 'updateOptions') {
          const defaultOptions = f.rules.event?.params?.defaultOptions;
          if (defaultOptions) f.options = defaultOptions;
          f.hidden = false;
        } else {
          f.hidden = true;
          newValues[f.name] = undefined;
          newValidations[f.name] = validateField(f, newValues[f.name], newValues);
        }
      }
    }
  } else if (field.rules) {
    const result = await applyRule(field.rules, newValues);
    const ruleType = field.rules?.event?.type;
    switch (ruleType) {
      case 'update':
      case 'updateMultiple':
        handleUpdateRule(result, newValues, newValidations, field);
        break;
      default:
        break;
    }
  }
}
/**
 * takes a string and if it contains references to object keys e.g ${firstName} it replaces it with its value
 * @param obj form data
 * @param str string to replace
 * @param stringify if the value is an object it returns the values as a string
 * @returns modified string
 */
export function replacePlaceholders(obj: any, str: string, stringify = true, idx?: number) {
  if (!str) return '';
  const regex = /\${(.*?)}/g;

  const replacedStr = str?.replace(regex, (match: any, key: any) => {
    const keys = key.split('||').map((k: any) => k.trim());

    for (let key of keys) {
      const value = key === 'index' ? idx : getValueFromNestedKey(obj, key);
      if (value !== undefined) {
        if (typeof value === 'object' && stringify) {
          return Object.values(value);
        }
        if (typeof value === 'number' && str.includes('£')) {
          return formatToMoney(value);
        }
        return value;
      } else {
        return 'N/A';
      }
    }

    return '';
  });

  return replacedStr.replace(/,,/g, ', ');
}

function getValueFromNestedKey(obj: any, key: any) {
  const keys = key.split('.');
  let value = obj;

  for (let nestedKey of keys) {
    if (!value || !value.hasOwnProperty(nestedKey)) {
      return undefined;
    }

    value = value[nestedKey];
  }

  return value;
}
